import * as React from 'react'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Lista = () => {
  return (
    <>
      <Seo title='Lista SDP-PGS za Gradsko vijeće - Crikvenica' />
      <Layout>
        <article className='lista'>
          <div className='kandidat'>
            <StaticImage
              src='../images/01_Mario_Kruzic.jpg'
              alt='Mario Kružić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>01. MARIO KRUŽIĆ (SDP)</h2>
              <h3>kandidat za Gradonačelnika Crikvenice</h3>
              <p>
                <a href='/biografija'>
                  Životopis na stranici gradonačelnika...
                </a>
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/02_Igor_Ivancic.jpg'
              alt='Igor Ivančić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>02. IGOR IVANČIĆ (nezavisni)</h2>
              <h3>kandidat za zamjenika Gradonačelnika</h3>
              <p>
                <a href='/zamjenik'>Životopis na stranici zamjenika...</a>
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/03_Branko_Klekovic.jpg'
              alt='Branko Kleković'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>03. BRANKO KLEKOVIĆ (PGS)</h2>
              <p>Rođen sam&nbsp; 07.10.1969. u Rijeci, na Su&scaron;aku.</p>
              <p>
                Pohađao sam Osnovnu &scaron;kolu Vladimir Nazor Crikvenica
                i&nbsp; Srednju građevinsku &scaron;kolu u Rijeci (geodetski
                tehničar), a zavr&scaron;etkom Geodetskog fakulteta u Zagrebu
                stekao sam zvanje diplomiranog inženjera geodezije te nakon toga
                i status ovla&scaron;tenog inženjera geodezije.
              </p>
              <p>
                1994. godine bio sam dobitnik Rektorove nagrade
                Sveučili&scaron;ta u Zagrebu za najbolji studentski rad.
              </p>
              <p>
                Imam 26 godina radnog iskustva u struci u realnom sektoru,
                aktivan sam u Hrvatskoj komori ovla&scaron;tenih inženjera
                geodezije, aktualni savjetnik Predsjednika Hrvatske komore
                ovla&scaron;tenih inženjera geodezije.
              </p>
              <p>
                Član sam Primorsko goranskog saveza od 2004., od 2011.
                predsjednik sam ogranka Crikvenica, od 2012. član
                predsjedni&scaron;tva, a od 2015. do danas potpredsjednik
                Primorsko- goranskog saveza.
              </p>
              <p>
                Od 2009. do danas sam aktivan član Gradskog vijeća Grada
                Crikvenica, u zadnjem mandatu član Odbora za izbor i imenovanje
                Gradskog vijeća Grada Crikvenice. U dva mandata, 2006.-2013. bio
                sam član Odbora za za&scaron;titu okoli&scaron;a i prostorno
                planiranje Gradskog vijeća Grada Crikvenice.
              </p>
              <p>
                Aktivan sam i u radu svog mjesnog odbora Crikvenica- Zapad čiji
                sam predsjednik Vijeća već 14 godina.
              </p>
              <p>
                Uz članstvo u stručnim i profesionalnim udrugama geodeta, član
                sam Hrvatskog lovačkog saveza, Malonogometnog kluba Duga i
                Udruge za narodne običaje Duga.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/04_Iva_Karlic.jpg'
              alt='Iva Karlić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>04. IVA KARLIĆ (SDP)</h2>
              <p>
                Rođena samu Rijeci 6.4.1987., od rođenja do danas živim i radim
                u Crikvenici.
              </p>
              <p>
                Nakon osnovne &scaron;kole u Crikvenici, zavr&scaron;ila sam
                Srednju medicinsku &scaron;kolu za zvanje farmaceutski tehničar.
              </p>
              <p>
                Preddiplomski studij jednopredmetne informatike upisala na
                Filozofskom fakultetu u Rijeci, a
              </p>
              <p>
                diplomski studij poslovne informatike na Odjelu za informatiku
                Sveučili&scaron;ta u Rijeci, gdje sam 2012. stekla zvanje
                magistar informatike, specijalista poslovne informatike.
              </p>
              <p>
                Svoja sam radna iskustva stjecala od srednjo&scaron;kolskih i
                studentskih dana radeći u ljekarni, pekari, blagajni Gradskog
                kupali&scaron;ta, da bih po zavr&scaron;etku studija, 2013-2014.
                godine, odradila stručno osposobljavanje bez zasnivanja radnog
                odnosa u ŽLU Crikvenica gdje sam zaposlena i danas.
              </p>
              <p>
                U posljednjem mandatu 2017.-2021., potpredsjednica sam Odbora za
                pred&scaron;kolski odgoj, &scaron;kolstvo i mladež Grada
                Crikvenice.
              </p>
              <p>
                Članica sam SDP-a od 2011. godine, od 2013. sam Članica gradskog
                odbora gradske organizacije&nbsp; SDP Crikvenica. Aktivna sam i
                kroz Forum žena SDP-a te sam predsjednica Foruma za Crikvenicu i
                članica Predsjedni&scaron;tva Foruma žena SDP PGŽ.
              </p>
              <p>
                Od 2018. do danas sam članica županijskog odbora županijske
                organizacije SDP PGŽ.
              </p>
              <p>Udana sam i majka uskoro četverogodi&scaron;njeg djeteta.</p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/05_Vedran_Antic.jpg'
              alt='Vedran Antić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>05. VEDRAN ANTIĆ (SDP)</h2>
              <p>
                Rođen sam 09.04.1982. godine u Rijeci, od rođenja živim u Selcu.
              </p>
              <p>
                Zavr&scaron;io sam ugostiteljsku &scaron;kolu, smjer konobar i
                od tog sam vremena vezan za ugostiteljstvo i turizam. Zadnjih 13
                godina sam obrtnik te vlasnik caffe bara Kiss.
              </p>
              <p>
                U politiku se uključujem prije 20 godina kao član i jedan od
                osnivača Foruma mladih SDP-a Crikvenica, a od 2005. godine sam
                aktivan član gradskog odbora te zadnja dva mandata zamjenik
                predsjednika gradske organizacije SDP-a Crikvenica.
              </p>
              <p>
                Od 2007. godine aktivno djelujem i kroz rad Mjesnog odbora Selce
                čiji sam predsjednik od 2015. godine do danas. U Mjesnom odboru
                poku&scaron;avamo s malim sredstvima koje dobivamo udovoljiti
                željama i potrebama mje&scaron;tana.
              </p>
              <p>
                Od većih projekata moram istaknuti da smo u centru mjesta
                napravili park sv. Josipa, jedno mjesto za razne prigode u
                sklopu kojeg je istoimena crkvica, a želim jo&scaron; naglasiti
                i da smo uredili jedan od frekventnijih ulaza u mjesto, ulicu
                Brzo, koja se spaja s magistralom.
              </p>
              <p>
                Bio sam predsjednik KUD-a Neven u jednom mandatu, tajnik RK
                Selce te član vi&scaron;e udruga.
              </p>
              <p>Oženjen sam i otac kćerke Nicol.</p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/06_Darko_Manestar.jpg'
              alt='Darko Manestar'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>06. DARKO MANESTAR (nezavisni)</h2>
              <p>
                Rođen sam u Dramlju 1949. godine. Osnovnu &scaron;kolu i
                gimnaziju zavr&scaron;io u Crikvenici. Godine 1974. diplomirao
                sam na Arhitektonskom fakultetu u Zagrebu. Nakon diplome bio sam
                zaposlen u projektnom birou Plan u Zagrebu, gdje sam radio kao
                odgovorni projektant, a zadnjih 6 godina kao voditelj projekata.
                Od 1992. godine vodim vlastiti projektni ured Area
                projektiranje, u kojemu radim na velikim projektima i za velike
                investitore. Ovla&scaron;teni sam arhitekt i energetski
                certifikator.
              </p>
              <p>
                Oženjen sam, imam tri uspje&scaron;ne kćeri i petero unučadi i
                svi obožavaju Crikvenicu.
              </p>
              <p>
                Nikada nisam bio u partiji niti u nekoj stranci, ali obavljajući
                svoj posao, nisam mogao pobjeći od politike u svim njenim
                pojavnostima.&nbsp;
              </p>
              <p>
                Ugledni roditelji, roditeljska kuća i brojni prijatelji u
                Crikvenici bili su čvrsta poveznica i doprinijeli su da mi
                Crikvenica sve vrijeme bude drugi dom. Crikvenica mi tada, kao i
                sada mladim &scaron;kolovanim ljudima, nažalost nije pružala
                uvjete za izgradnju stručne karijere. U zadnjih trideset godina
                nastojao sam svojim aktivnostima i strukom doprinijeti razvoju
                Crikvenice. Surađivao sam sa svim gradskim upravama. Izradio sam
                niz idejnih rje&scaron;enja i urbanističkih i raznih drugih
                prijedloga, inicirao niz projekata, od kojih su neki i
                realizirani. Izradio sam prvi projekt difuznog hotela, idejno
                rje&scaron;enje marine, projekt Balustrade, idejni&nbsp; projekt
                sportske dvorane i plivali&scaron;ta, idejni sam začetnik
                uređenja (ne i realizacije) Trga Stjepana Radića, idejni
                začetnik Romskog naselja, niza urbanističkih projekata i
                studija. Zadnja realizacija je projekt Doma za starije
                &bdquo;Salvia&ldquo;.
              </p>
              <p>
                Svoju stručnost, znanje i iskustvo, entuzijazam i energiju i
                nadasve ljubav prema rodnom gradu, spreman sam uložiti u
                osmi&scaron;ljavanje i realizaciju planova i projekata, koji će
                omogućiti da Crikvenica postane GRAD S PET ZVJEZDICA.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/07_Viktor_Juric.jpg'
              alt='Viktor Jurić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>07. VIKTOR JURIĆ (SDP)</h2>
              <p>Imam 33 godine, živim u Dramlju.</p>
              <p>
                Zavr&scaron;io sam Srednju &scaron;kolu Antuna Barca u
                Crikvenici, smjer hotelijersko- turistički tehničar,
              </p>
              <p>
                a potom i vi&scaron;u trenersku &scaron;kolu, smjer nogomet, pri
                Kineziolo&scaron;kom fakultetu u Zagrebu.
              </p>
              <p>
                U svom sam radnom vijeku radio kao konobar, fitness trener,
                agent u osiguranju, a od 2016. do danas sam komercijalist u
                Trgovini Dramalj doo.
                <br />
                <br />U jednom mandatu bio sam član MO Dramalj.
              </p>
              <p>
                Oduvijek sam bio aktivan kroz klubove i udruge, nekada sam bio
                član &Scaron;RD Lovrata Dramalj, a danas sam aktivan kroz
                članstvo u HGU, udrugu mladeži&nbsp; Sv. Jelena Dramalj&nbsp;,
                TS Baruffa&nbsp;&nbsp;i
                <br />
                veterana NK Crikvenica.
              </p>
              <p>Oženjen sam i otac dvoje djece.</p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/08_Marko_Vukelic.jpg'
              alt='Marko Vukelić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>08. MARKO VUKELIĆ (SDP)</h2>
              <p>
                Rođen sam 10. svibnja 1984. godine u Rijeci. Po struci sam
                diplomirani pravnik i zaposlen sam kao odvjetnik/partner u
                odvjetničkom dru&scaron;tvu u Rijeci.
              </p>
              <p>
                U političkom životu Crikvenice sudjelujem kao predsjednik
                Mjesnog odbora Crikvenica &ndash; centar,&nbsp; te sam
                predsjednik Upravnog vijeća Thalassotherapia Crikvenica. Oženjen
                sam i otac jednog djeteta.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/09_Ani_Car.jpg'
              alt='Ani Car'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>09. ANI CAR (SDP)</h2>
              <p>
                Rođena sam Crikveničanka, rođena 1961. godine, udovica i majka
                dva odrasla sina.
              </p>
              <p>
                Cijeli svoj profesionalni život posvetila sam medicini i radu s
                pacijentima. Posljednjih jedanaest godina uspje&scaron;no vodim
                privatnu ordinaciju obiteljske medicine.
              </p>
              <p>
                Da poznajem problematiku javne uprave i rad kroz Gradsko vijeće,
                dokazala sam kroz dva mandata vijećnice u Gradskom vijeću Grada
                Crikvenice, a bila sam i predsjednica Vijeća Mjesnog odbora
                Zapad.
              </p>
              <p>
                Kao predsjednica Turističke zajednice Grada Crikvenice u dva
                mandata, dobro sam upoznata s funkcioniranjem sustava u kojem
                je, prema trenutno važećem zakonu, gradonačelnik ujedno i
                predsjednik TZ.
              </p>
              <p>
                Osam sam godina bila predsjednica Sindikata zdravstvenih radnika
                te dobro poznajem problematiku za&scaron;tite radnika i borbe za
                njihova prava. &nbsp;
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/10_Ruby_Montanari_Knez.jpg'
              alt='Ruby Montanari- Knez'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>10. RUBY MONTANARI- KNEZ (nezavisna)</h2>
              <p>
                Rođena sam 28.08.1983. u Londonu, gdje sam provela veći dio
                djetinjstva. Toliko sam voljela na&scaron; kraj da sam s nepunih
                13 godina uvjerila roditelje da mi dopuste da se preselim k
                nonićima u Kraljevicu. Ponosna sam Crikveničanka po udaji već
                jedanaestu godinu i majka triju djevojčica.
              </p>
              <p>
                Nakon jezične gimnazije, zavr&scaron;ila sam preddiplomski
                studij na Ekonomskom fakultetu 2013.&nbsp; paralelno gradeći
                glazbenu karijeru kao članica kućnog benda na Novoj tv i
                pjevačica u Denis &amp; Denis. Tada sam već bila i majka po prvi
                puta i vodila svoj prvi privatni posao; hotel za pse.
              </p>
              <p>
                Iste godine postajem član Upravnog odbora
                premijerliga&scaron;kog rukometnog kluba Crikvenica te vodim PR
                kluba. Dvije sezone sam bez dodijeljenog budžeta organizirala
                razne manifestacije zabavnog karaktera. Klub je bio odlična
                platforma za humanitarni rad. Organizirala sam nekoliko
                uspje&scaron;nih humanitarnih akcija kojima smo uz pomoć građana
                Crikvenice darivali djecu iz dječjeg doma 'Izvor' u Selcima,
                skupljali hranu i odjeću za potrebite obitelji na&scaron;eg
                područja, te nudili starijim građanima pomoć u fizičkim
                poslovima.
              </p>
              <p>
                2015. sam sa suprugom pokrenula Monty's Dog Beach, tematiziranu
                plažu za pse, prvu takve vrste u Hrvatskoj. Plaža je kao
                turistički proizvod iz Crikvenice obi&scaron;la svijet putem
                medija i osvojila brojne nagrade od kojih bih istaknula 3.mjesto
                za Plažu godine HTZ-a 2016. Otvorili smo jo&scaron; jednu plažu
                u gradu Rabu 2018., a na&scaron; brend je prepoznat i u Long
                Beachu u Californiji gdje uskoro otvaramo treću plažu.
              </p>
              <p>
                Konzultantica sam za pet-friendly turizam Crne Gore, a na poziv
                turističkih zajednica održavam pet- friendly radionice kako bi
                se podigla razina kvalitete pet- friendly iznajmljivanja i
                infrastrukture.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/11_Damir_Avdagic.jpg'
              alt='Damir Avdagić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>11. DAMIR AVDAGIĆ (nezavisni)</h2>
              <p>
                Rođen sam u travnju 1976. godine u Rijeci. Po struci sam
                liječnik.&nbsp;
              </p>
              <p>
                Živim u Crikvenici, gdje sam godinama bio aktivan kao
                predsjednik Ko&scaron;arka&scaron;koga kluba Crikvenica i
                predsjednik Gradskoga dru&scaron;tva Crvenoga križa Crikvenica.
              </p>
              <p>Oženjen sam i otac jednoga djeteta.</p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/12_Dijana_Luksic.jpg'
              alt='Dijana Lukšić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>12. DIJANA LUKŠIĆ (nezavisna)</h2>
              <p>Rođena sam 27.09.1967. godine u Rijeci.</p>
              <p>
                Po struci sam ugostitelj i potičem iz ugostiteljske obitelji
                Bego iz Crikvenice gdje sam u mladosti uspje&scaron;no radila i
                vodila dva poznata ugostiteljska objekta.
              </p>
              <p>
                Nakon udaje posvećujem se majčinstvu i nastavljam svoj život u
                Jadranovu. Majka sam dvojice sinova,&nbsp; sad već odraslih i
                uspje&scaron;nih mladih ljudi.
              </p>
              <p>
                2017. godine vraćam se u biznis, kada suprug i ja u Crikvenici
                obnavljamo obiteljsku kuću i pretvaramo je u apartmanski
                kompleks visoke kategorije te nastavljamo uspje&scaron;no
                poslovanje.
              </p>
              <p>
                Zahvaljujući mlađem sinu Mauru Luk&scaron;iću, ribolovcu
                svjetskog sjaja, 2010. godine i ja se pridružujem sportskom
                ribolovu u P&Scaron;RD Arbun Crikvenica. Već nakon dvije godine
                ulazim u sastav hrvatske ženske reprezentacije u sportskom
                ribolovu na moru, uz dva nastupa na svjetskim prvenstvima, prvo
                u &Scaron;panjolskoj i drugo u Portugalu, gdje ekipno postajemo
                viceprvakinje svijeta.
              </p>
              <p>
                Kao nezavisna, članica sam mjesnog odbora Jadranovo od 2017.
                godine.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/13_Maja_Simic_Todorovic.jpg'
              alt='Maja Šimić Todorović'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>13. MAJA ŠIMIĆ TODOROVIĆ (nezavisna)</h2>
              <p>
                Rođena sam 11.05.1990. u Konjicu, BiH. U Crikvenici sam
                zavr&scaron;ila Srednju &scaron;kola dr. Antuna Barca, smjer
                hotelijersko- turistički tehničar. PO zavr&scaron;etku Pomorskog
                fakulteta u Rijeci, 2014. godine stječem zvanje Magistra
                inženjerka logistike i menadžmenta u pomorstvu. Kako radim na
                svom stručnom usavr&scaron;avanju, od 2019. godine imam i
                certifikat u području javne nabave.
              </p>
              <p>
                Dosada&scaron;nje radno iskustvo vezano mi je uz struku, pa sam
                Stručno osposobljavanje bez zasnivanja radnog odnosa odradila u
                Županijskoj lučkoj upravi Krk, nakon toga radila sam godinu dana
                u ŽLU Crikvenica te nakon toga jo&scaron; dvije godine u ŽLU
                Krk, sve do 31.8.2020. i od tada sam nezaposlena.
              </p>
              <p>
                Udana sam i majka jedne djevojčice, a slobodno vrijeme provodim
                s obitelji i prijateljima, kuham, čitam, vodim aktivan život
                koji uključuje &scaron;etnje prirodom, planinarenje, vožnja
                biciklom, trening.
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/14_Silvija_Orac_Matic.jpg'
              alt='Silvija Orač Matić'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>14. SILVIJA ORAČ MATIĆ (PGS)</h2>
              <p>Rođena sam 13.03.1981.godine u Rijeci.</p>
              <p>
                Od rođenja živim u Crikvenici gdje sam i pohađala osnovnu
                &scaron;kolu, a srednju Prometnu &scaron;kolu, u kojoj&nbsp; sam
                stekla zvanje tehničar po&scaron;tanskog prometa,
                zavr&scaron;ila sam u Rijeci.&nbsp;
              </p>
              <p>
                Trenutno sam zaposlena kao poslovođa u međunarodnoj tvrtki čija
                je osnovna djelatnost maloprodaja odjeće za cijelu obitelj.
              </p>
              <p>
                Pro&scaron;le godine upisala sam, i u prosincu zavr&scaron;ila,
                program osposobljavanja za poslove krojenja i &scaron;ivanja i
                upravo u tome vidim svoju poslovnu budućnost. Voljela bih da
                moje znanje i vje&scaron;tine u tom području ne ostanu samo na
                razini hobija.
              </p>
              <p>
                Mi&scaron;ljenja sam da bi upravo grad i zajednica u kojoj
                živimo trebali konstantno i kroz razne potpore poticati mlade
                željne rada da se pokrenu i ostvare u svojim zanimanjima. Mnogo
                puta pojedinac ne može uspjeti sam već mu je potrebna pomoć i
                poticaj onih u koje vjeruje i kome su dali svoj glas.
              </p>
              <p>
                U politički život uključila sam se kao članica PGS-a vođena
                ljubavlju prema svom kraju. Oduvijek se trudim i želim da grad u
                kojem živim od rođenja bude bolje i sretnije mjesto, kako za
                najmlađe tako i za one najstarije članove na&scaron;eg
                dru&scaron;tva. Upravo ta misao je i bila nit vodilja u mom
                političkom angažmanu.
              </p>
              <p>
                Prethodne dvije godine bila sam člana&nbsp; Odbora za zdravstvo
                i socijalnu skrb, u kojem je opet riječ o najranjivijim
                članovima na&scaron;eg dru&scaron;tva koji nikako i nikada ne
                smiju biti zaboravljeni.&nbsp;
              </p>
              <p>
                Svoje slobodno vrijeme najradije provodim u prirodi sa svoje
                dvije kćeri i suprugom.&nbsp;
              </p>
            </div>
          </div>
          <div className='kandidat'>
            <StaticImage
              src='../images/15_Mladen_Buneta.jpg'
              alt='Mladen Buneta'
              layout='constrained'
              placeholder='blur'
              quality='100'
              objectFit='contain'
              objectPosition='0 0'
              aspectRatio='1'
              imgClassName='kandidat-slika'
            />
            <div className='bio'>
              <h2>15. MLADEN BUNETA (SDP)</h2>
              <p>Rođen 24.05.1979. godine, živim u naselju Hrusta.</p>
              <p>
                Zavr&scaron;io sam srednju trogodi&scaron;nju &scaron;kolu, po
                zanimanju sam kuhar.
              </p>
              <p>Zaposlen sam u dostavnoj službi tvrtke Overseas.</p>
              <p>
                U slobodno vrijeme bavim se sportom, sada igram rekreativno u
                MNK Galapagos Hrusta.
              </p>
              <p>
                Oženjen sam i otac tri sina, Lucasa, Ga&scaron;para i Frana.
              </p>
            </div>
          </div>
        </article>
      </Layout>
    </>
  )
}

export default Lista
